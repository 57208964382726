



















































































































































































import { isEmpty } from "lodash-es";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { TrainingGroundEventViewmodel } from "../viewmodels/training-ground-event-viewmodel";

@Observer
@Component({
  components: {
    CharacterRow: () => import("@/components/character/character-row.vue"),
    CharacterAvatar: () => import("@/components/character/character-avatar.vue"),
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    OwnedMonsterFilter: () => import("../components/owned-monster-filter.vue"),
  },
})
export default class SelectMonsterDialog extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;

  get emptySearchParam() {
    return isEmpty(this.vmEvent.searchParams);
  }
}
